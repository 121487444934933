import "animate.css"
import "./styles/global.scss"
import "./App.css"
import CryptoSwap from "./components/CryptoSwap";
import logo from "./assets/images/logo.png"
import twitter from "./assets/icons/twitter.svg"
import telegram from "./assets/icons/telegram.svg"
import {ConnectKitButton} from "connectkit";
import {useState} from "react";
import {AnnouncementModal} from "./components/AnnouncementModal";

function App() {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <main>
      <div className="header">
        <img src={logo} alt="logo" className="logo"/>
        <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
      </div>
      <div className='main-content'>
        <div>
          <h1>
            Welcome to the beta testing phase of <br/><span className="gold">Mofi Finance</span>!
          </h1>
          <p>
            Thank you for assisting us in developing our products!<br/>
            Support team: <a href="mailto:support@mofi.finance">support@mofi.finance</a>
          </p>
        </div>

        <CryptoSwap/>
      </div>
      <footer className="footer">
        <div className="footer-top">
          <div className='footer-right'>
            <a href='https://t.me/mofi_finance'>
              <img alt='telegram' height="25" width="25" src={telegram} />
            </a>
            <a href='https://x.com/mofi_finance'>
              <img alt='twitter' height="30" width="30" src={twitter} />
            </a>
            <div className='connectkit-btn-white'><ConnectKitButton label='Connect Wallet'/></div>
          </div>
        </div>
        <div className='footer-bottom'>
          <p className="footer-reserved">Copyright © 2024 Mofi Finance. All rights reserved.</p>
          <address>
            1234 Ocean Drive, Apartment 205, Miami, FL 33139 USA
          </address>

        </div>
      </footer>
    </main>
  );
}

export default App;
