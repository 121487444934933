/*
@Author: Papoi
@Created at: 2024-4-14
@Description: search cryptos modal component
*/

import * as React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';

import CryptoItem from './CryptoItem';
import PinCryptoItem from './PinCryptoItem';

interface SearchCryptoProps {
    open: boolean,
    onClose: () => void,
    setToken: (token: string, icon: string, price: number) => void,
    tokens: any[]
}

const SearchCrypto: React.FC<SearchCryptoProps> = ({
    open,
    onClose,
    setToken,
                                                       tokens
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(tokens);

    useEffect(() => {
        // Filter the data based on the search value
        const filteredTokens = tokens.filter(item =>
            item.symbol.toLowerCase().includes(searchValue.toLowerCase())
        );

        setFilteredData(filteredTokens);
    }, [searchValue]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    return (
        <div>
            <Modal
                title="Select a token"
                centered
                style={{ height: "600px", maxHeight: "600px", overflowY: 'auto' }}
                open={open}
                onOk={onClose}
                onCancel={onClose}
                footer={null}
            >
                <Input
                    placeholder="Search token"
                    value={searchValue}
                    onChange={handleSearchChange}
                />

                <div className="p-2">
                    {tokens.map(item => (
                        <PinCryptoItem
                            key={item.symbol.toUpperCase()}
                            name={item.name}
                            token={item.symbol.toUpperCase()}
                            icon={item.image}
                            price={item.current_price}
                            setToken={() => setToken(item.symbol.toUpperCase(), item.image, item.current_price)}
                        />
                    ))}
                </div>

                <hr className="mb-2" />
                {
                    searchValue === '' ? (
                      tokens.map(item => (
                            <CryptoItem
                              key={item.symbol.toUpperCase()}
                              name={item.name}
                              token={item.symbol.toUpperCase()}
                              icon={item.image}
                              price={item.current_price}
                              setToken={() => setToken(item.symbol.toUpperCase(), item.image, item.current_price)}
                            />
                        ))) : (filteredData.map(item => (
                            <CryptoItem
                              key={item.symbol.toUpperCase()}
                              name={item.name}
                              token={item.symbol.toUpperCase()}
                              icon={item.image}
                              price={item.current_price}
                              setToken={() => setToken(item.symbol.toUpperCase(), item.image, item.current_price)}
                            />
                        )))
                }
            </Modal>
        </div>
    );
}

export default SearchCrypto;
