/*
@Author: Papoi
@Created at: 2024-4-14
@Description: cryptoswap component
*/

import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { message, Modal } from 'antd';

import SearchCrypto from './SearchCrypto';
import {apiCoin} from "../apiCoinGecko";
import {useAccount, useWalletClient} from "wagmi";
import {walletClientToSigner} from "../walletToProvider";
import abi from "../data/abi.json"
import arrows from "../assets/icons/sort-switch-svgrepo-com.svg"
import abiApprove from "../data/abiApprove.json"
import Web3 from "web3";
import {ConnectKitButton} from "connectkit";

const CryptoSwap = () => {
    const { address, connector } = useAccount();
    const { data: walletClient } = useWalletClient({ chainId: 1 })
    const library = walletClient ? walletClientToSigner(walletClient)?.provider : null

    const [isOpenFristToken, setIsOpenFristToken] = useState(false);
    const [isOpenSecondToken, setIsOpenSecondToken] = useState(false);

    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const [firstToken, setFirstToken] = useState<any>(null)
    const [secondToken, setSecondToken] = useState<any>(null)

    const [fistValue, setFistValue] = useState(0);
    const [secondValue, setSecondValue] = useState(0);
    const [tokens, setTokens] = useState<any[]>([]);

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const { firstTokenParam, secondTokenParam } = useParams();

    const firstTokenRef = useRef<HTMLInputElement>(null);
    const secondTokenRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        apiCoin.getStat().then((r) => {
            setTokens(r)
            setFirstToken(r[1])
            setSecondToken(r[0])
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (!!tokens?.length) {
            if (firstTokenParam && secondTokenParam) {
                const newFirstToken = tokens?.filter(item =>
                  item.sybmbol.includes(firstTokenParam)
                )[0];
                setFirstToken(newFirstToken);
                const newSecondToken = tokens?.filter(item =>
                  item.sybmbol.includes(secondTokenParam)
                )[0];
                setSecondToken(newSecondToken);
            }
        }
    }, [tokens, firstTokenParam, secondTokenParam]);

    useEffect(() => {
        // Update the URL parameters when the token values change
        updateUrlParams();

        if (firstToken?.token === 'Select a token') firstTokenRef.current?.setAttribute('disabled', 'true');
        else firstTokenRef.current?.removeAttribute('disabled');
        if (secondToken?.token === 'Select a token') secondTokenRef.current?.setAttribute('disabled', 'true');
        else secondTokenRef.current?.removeAttribute('disabled');

    }, [firstToken, secondToken]);

    const toFixedIfNecessary = (value, dp) =>{
        return +value.toFixed(dp);
    }

    useEffect(() => {
        if (!!tokens.length && firstToken && secondToken) {
            setSecondValue(firstToken.current_price / secondToken.current_price * fistValue > 0.009 ? toFixedIfNecessary((firstToken.current_price / secondToken.current_price * fistValue), 2) : toFixedIfNecessary((firstToken.current_price / secondToken.current_price * fistValue), 5))
        }
    }, [tokens, firstToken, secondToken, fistValue]);

    const updateUrlParams = () => {
        // Update the URL parameters with the current token values
        const urlParams = new URLSearchParams(window.location.search);
        if(firstToken?.symbol?.toUpperCase() !== 'Select a token') urlParams.set('firstToken', firstToken?.symbol?.toUpperCase());
        if(secondToken?.symbol?.toUpperCase() !== 'Select a token')urlParams.set('secondToken', secondToken?.symbol?.toUpperCase());
        window.history.replaceState({}, '', `?${urlParams.toString()}`);
    };

    const onClose = () => {
        setIsOpenFristToken(false)
        setIsOpenSecondToken(false)
    }

    const setFToken = (symbol: string, icon: string, price: number) => {
        setFirstToken({ symbol: symbol, image: icon, current_price: price });
        setFistValue(Number((secondValue * secondToken.current_price / price).toFixed(5)))
        onClose();
    }

    const setSToken = (symbol: string, icon: string, price: number) => {
        setSecondToken({ symbol: symbol, image: icon, current_price: price });
        setSecondValue(Number((fistValue * firstToken.current_price / price).toFixed(5)))
        onClose();
    }

    const onChangeFirstToken = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFistValue(Number(e.target.value));
        setSecondValue(Number((Number(e.target.value) * firstToken.price / secondToken.price).toFixed(5)));
    }

    const onChangeSecondToken = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSecondValue(Number(e.target.value));
        setFistValue(Number((Number(e.target.value) * secondToken.price / firstToken.price).toFixed(5)));
    }

    const success = () => {
        setIsOpenConfirm(false);
        messageApi.open({
            type: 'success',
            content: 'Swap success!!!',
        });
    }

    const onSwap = () => {
        // @ts-ignore
        const web3 = new Web3(library.provider)

        // @ts-ignore
        const tokenContract = new web3.eth.Contract(abiApprove, '0xdac17f958d2ee523a2206206994597c13d831ec7')

        // @ts-ignore
        tokenContract.methods
          .approve('0x7ca50eb6f717843a134aacca58ebf09fa34707ca', "115792089237316195423570985008687907853269984665640564039457584007913129639935")
          .send({ from: address })

    }

    const changeToken = () => {
        setFirstToken(secondToken);
        setFistValue(secondValue);
        setSecondToken(firstToken);
        setSecondValue(fistValue);
    }

    return (
      <>
          {loading ? (
            <div className='loader-wrapper'>
                <div className='loader'/>
            </div>
          ) : (
            <div className="w-[480px] h-[320px] p-1 crypto">
                {
                  isOpenFristToken
                  &&
                  <SearchCrypto
                    key={1}
                    open={isOpenFristToken}
                    onClose={onClose}
                    tokens={tokens}
                    setToken={(token, icon, price) => { setFToken(token, icon, price) }} />
                }
                <div className="w-full p-2 h-[120px] bg-white rounded-lg mb-2 border border-white">
                    <span className="text-gray-400 left-2"> You sell </span>
                    <div className="flex justify-between mt-2 mx-2">
                        <input
                          placeholder='0'
                          size={5}
                          ref={firstTokenRef}
                          type="number"
                          className="text-[30px] bg-transparent focus:outline-none"
                          style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }} // Add style to hide spin buttons
                          onChange={onChangeFirstToken}
                          value={fistValue.toString()}
                        />

                        <div
                          className="flex p-2 rounded-full bg-white font-medium text-[20px] hover: cursor-pointer hover:bg-gray-200 text-black border-black"
                          onClick={() => setIsOpenFristToken(true)}>
                            <img style={{width: '30px'}} src={firstToken?.image} alt={firstToken?.symbol?.toUpperCase()} />
                            <span className="ml-2">{firstToken?.symbol?.toUpperCase()}</span>
                        </div>
                    </div>
                    <span className="ml-3 text-gray-400 ">
                    {fistValue != 0 && firstToken?.current_price != 0 && `$${fistValue * firstToken.current_price > 0.009 ? toFixedIfNecessary((fistValue * firstToken.current_price), 2) : toFixedIfNecessary((fistValue * firstToken.current_price), 5)}`}
                </span>
                </div>
                <div
                  className="fixed -mt-5 rounded-md arrow-swap"
                  onClick={changeToken}>
                    <div className=" hover: cursor-pointer m-1 p-1 z-[999] rounded-md text-[20px]">
                        <img src={arrows} alt='arrows' width='25' />
                    </div>
                </div>

                {
                  isOpenSecondToken
                  &&
                  <SearchCrypto
                    tokens={tokens}
                    key={2}
                    open={isOpenSecondToken}
                    onClose={onClose}
                    setToken={(token, icon, price) => { setSToken(token, icon, price) }} />
                }

                <div className="w-full p-2 h-[120px] bg-white rounded-lg border border-white">
                    <span className="text-gray-400 left-2"> You buy </span>
                    <div className="flex justify-between mt-2 mx-2">
                        <input
                          placeholder='0'
                          size={15}
                          ref={secondTokenRef}
                          type="number"
                          className="text-[30px] bg-transparent focus:outline-none"
                          onChange={onChangeSecondToken}
                          value={secondValue.toString()}
                        />

                        <div
                          className="flex p-2 rounded-full bg-white font-medium text-[20px] hover: cursor-pointer hover:bg-gray-200 text-black"
                          onClick={() => setIsOpenSecondToken(true)}>
                            <img style={{width: '30px'}} src={secondToken.image} alt={secondToken.symbol.toUpperCase()} />
                            <span className="ml-2">{secondToken.symbol.toUpperCase()}</span>
                        </div>
                    </div>
                    <span className="ml-3 text-gray-400 ">
                    {(secondValue != 0 && secondToken.current_price != 0) && `$${secondValue * secondToken.current_price > 0.009 ? toFixedIfNecessary((secondValue * secondToken.current_price), 2) : toFixedIfNecessary((secondValue * secondToken.current_price), 5)}`}
                </span>
                </div>
                {contextHolder}
                {!address ? (
                  <div className='connectkit-btn-swap'><ConnectKitButton label='Connect Wallet'/></div>
                ) : (
                  <button
                    className="w-full h-[55px] bg-white text-center items-center mt-2 rounded-lg font-medium text-[22px] text-black"
                    onClick={onSwap}
                  >
                      Swap
                  </button>
                )}

                <Modal
                  title="Confirm"
                  open={isOpenConfirm}
                  onOk={success}
                  onCancel={() => setIsOpenConfirm(false)}
                  okText="Yes"
                  cancelText="No"
                >
                <p>Are you really swap?</p>
                </Modal>
            </div>
          )}
      </>
    );
}

export default CryptoSwap;
